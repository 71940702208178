import { FC, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Collapse } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import {
  DEFAULT_LIMIT_ITEMS,
  DragItemTypes,
  NESTED_DEFAULT_LIMIT,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';

import { DropdownButtonItems } from './DropdownItemButtons';

import styles from './DroppableDropDown.module.scss';

interface IDroppableDropDown {
  item: TTransformMenuItem;
  items: TTransformMenuItem[];
  id: string;
  nestingLevel?: number;
  limitNestingLevel?: number;
  nestedElementLimit?: number;
  moveMenuItem: (id: string, hoverIndex: number) => void;
  findMenuItem: (id: string) => { index: number } | undefined;
}

interface Item {
  id: string;
  originalIndex: number;
}

export const DroppableDropDown: FC<IDroppableDropDown> = ({
  item,
  id,
  items,
  moveMenuItem,
  findMenuItem,
  nestingLevel = 0,
  limitNestingLevel = NESTED_DEFAULT_LIMIT,
  nestedElementLimit = DEFAULT_LIMIT_ITEMS,
}) => {
  const canUpdate = usePermission('MENU_UPDATE');

  const isCollapse = items.some((menuItem) => menuItem.parentId === item.id);

  const childElements = items.filter((menuItem) => menuItem.parentId === item.id);

  const [opened, setOpened] = useState<boolean>(false);

  const originalIndex = findMenuItem(id)?.index;

  const [, drag] = useDrag(
    () => ({
      type: DragItemTypes.MENU,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (dragItem, monitor) => {
        const { id: droppedId, originalIndex: index } = dragItem;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveMenuItem(droppedId, index || 0);
        }
      },
    }),
    [id, originalIndex, moveMenuItem],
  );

  const [, drop] = useDrop(
    () => ({
      accept: DragItemTypes.MENU,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const hoverIndex = findMenuItem(id)?.index;
          moveMenuItem(draggedId, hoverIndex || 0);
        }
      },
    }),
    [findMenuItem, moveMenuItem],
  );

  const isDisableAddBtn =
    nestingLevel >= limitNestingLevel || childElements.length >= nestedElementLimit;

  const handleOpen = () => {
    setOpened((prev) => !prev);
  };

  const onDragHandler = (node: HTMLDivElement) => {
    drag(drop(node));
  };

  return (
    <>
      <Box
        className={cn(styles.root, !item.isVisible && styles.unvisible, 'can')}
        onClick={handleOpen}
        ref={onDragHandler}
      >
        {isCollapse && (
          <Box
            style={{
              marginRight: 12,
              transform: !opened ? 'rotate(180deg)' : '',
            }}
            className='can'
          >
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='can'
            >
              <path
                className='can'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.7489 7.17754C11.4142 7.51227 10.8715 7.51227 10.5368 7.17754L6 2.64077L1.46323 7.17754C1.1285 7.51227 0.585787 7.51227 0.251052 7.17754C-0.083684 6.8428 -0.083684 6.30009 0.251052 5.96536L5.39391 0.822501C5.72864 0.487766 6.27136 0.487766 6.60609 0.822501L11.7489 5.96535C12.0837 6.30009 12.0837 6.8428 11.7489 7.17754Z'
                fill='#121212'
              />
            </svg>
          </Box>
        )}

        <span className='can'>
          <b className='can'>{item?.name} &nbsp;</b>
        </span>
        {canUpdate && (
          <DropdownButtonItems
            item={item}
            parentId={item.id}
            url={item.setting.url}
            isDisable={isDisableAddBtn}
          />
        )}
      </Box>
      <Box
        style={{
          marginLeft: 40,
        }}
      >
        <Collapse in={opened} transitionDuration={100} transitionTimingFunction='linear'>
          {!!items.length &&
            childElements.map((itemInner: TTransformMenuItem) => (
              <DroppableDropDown
                items={items}
                item={itemInner}
                nestingLevel={nestingLevel + 1}
                limitNestingLevel={limitNestingLevel}
                nestedElementLimit={nestedElementLimit}
                id={itemInner.id}
                key={itemInner.id}
                moveMenuItem={moveMenuItem}
                findMenuItem={findMenuItem}
              />
            ))}
        </Collapse>
      </Box>
    </>
  );
};
