import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Title } from '@mantine/core';
import update from 'immutability-helper';

import {
  DragItemTypes,
  limitNestingItems,
  limitNestingLevel,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';

import { DroppableDropDown } from './DroppableDropDown';
import { MainMenu } from './MainMenu';

import {
  setAddMenuItemsModalOpened,
  setMenuItemsAction,
  setParentId,
} from '@/store/slices/menu/menuItems';
import { selectCurrentMenuType } from '@/store/slices/menu/menuTypes';

interface IProps {
  items: TTransformMenuItem[] | null;
}

export const MenuTabSite: FC<IProps> = ({ items }) => {
  const dispatch = useDispatch();
  const currentMenuType = useSelector(selectCurrentMenuType);

  const [menuItems, setMenuItems] = useState<TTransformMenuItem[] | null>(items);

  const limitNesting = (currentMenuType && limitNestingLevel[currentMenuType]) || 1;
  const limitItems = (currentMenuType && limitNestingItems[currentMenuType]) || 4;

  const findMenuItem = useCallback(
    (id: string) => {
      if (!menuItems) return;
      const menu = menuItems.filter((menuItem) => menuItem.id === id)[0];
      return {
        menu,
        index: menuItems.indexOf(menu),
      };
    },
    [menuItems],
  );

  const moveMenuItem = useCallback(
    (hoverId: string, oldIndex: number) => {
      const item = findMenuItem(hoverId);
      if (!item || !menuItems) return;

      const { menu, index: currentIndex } = item;
      setMenuItems(
        update(menuItems, {
          $splice: [
            [currentIndex, 1],
            [oldIndex, 0, menu],
          ],
        }).map((menuItem, index) => ({ ...menuItem, sortWeight: index + 1 })),
      );
    },
    [findMenuItem, menuItems, setMenuItems],
  );

  const [, drop] = useDrop(() => ({ accept: DragItemTypes.MENU }));

  useEffect(() => {
    dispatch(setMenuItemsAction(menuItems));
  }, [dispatch, menuItems]);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  const addItemHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(setParentId(null));
      dispatch(setAddMenuItemsModalOpened(true));
    },
    [dispatch],
  );

  return (
    <Box
      ref={drop}
      style={{
        padding: '24px 0 34px 0',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: 'calc(100% - 100px)',
        maxHeight: '100%',
        overflow: 'scroll',
      }}
    >
      <Title order={5} c='dimmed'>
        Перетащите элементы меню, чтобы изменить их порядок
      </Title>
      <MainMenu name='prosv.ru' addItemHandler={addItemHandler}>
        {menuItems &&
          menuItems
            .filter((item) => !item.parentId)
            .map((item: TTransformMenuItem) => {
              return (
                <DroppableDropDown
                  item={item}
                  items={menuItems}
                  limitNestingLevel={limitNesting}
                  nestedElementLimit={limitItems}
                  id={item.id}
                  key={item.id}
                  moveMenuItem={moveMenuItem}
                  findMenuItem={findMenuItem}
                />
              );
            })}
      </MainMenu>
    </Box>
  );
};
