import { FC, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { Box, Paper, Tabs } from '@mantine/core';

import { ModalConfirm } from '@/ui/components/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import styles from './UmkDetail.module.scss';

import { ActionButtons } from '@/pages/UmkDetail/ActionButtons';
import { ContentEditorWrapper } from '@/pages/UmkDetail/ContentEditorWrapper/ContentEditorWrapper';
import { UMK_FORM_ID } from '@/pages/UmkDetail/UmkDetail.constants';
import { getBreadcrumbs } from '@/pages/UmkDetail/UmkDetail.helpers';
import { useUmkDetailState } from '@/pages/UmkDetail/UmkDetail.state';
import { UmkDetailGeneralInfo } from '@/pages/UmkDetail/UmkDetailGeneralInfo';

export const UmkDetail: FC = () => {
  const state = useUmkDetailState();

  useEffect(() => {
    if (state.isNewUmk) {
      state.handleSetDrafts();
    } else {
      state.fetchUmk().catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.id) {
    redirect('/umk');
    return null;
  }

  const breadcrumbs = getBreadcrumbs(state.isNewUmk, state.id);

  return (
    <Box className={styles.root}>
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={state.title}
        rightButton={
          <ActionButtons
            isNewUmk={state.isNewUmk}
            onDelete={() => state.setDeleteModalOpen(true)}
            previewUrl={state.previewUrl}
            permissions={state.permissions}
            onSubmit={() => state.resetEditorModifiedState()}
            formId={UMK_FORM_ID}
            isShowPreviewBtn
          />
        }
      />
      <Paper radius={8} p={24} shadow='xs' className={styles.contentWrapper}>
        <Tabs
          color='teal'
          defaultValue='general'
          style={{ height: '100%', position: 'relative' }}
          onChange={(tabName) => tabName && state.setActiveTab(tabName)}
        >
          <Tabs.List style={{ borderBottom: 'none' }}>
            <Tabs.Tab value='general' color='primary'>
              Общее
            </Tabs.Tab>

            <Tabs.Tab value='firstBlockEditor' color='primary' disabled={state.isLoading}>
              {state.firstTabLabel}
            </Tabs.Tab>
            {!state.isDirections && (
              <Tabs.Tab value='secondBlockEditor' color='primary' disabled={state.isLoading}>
                Редактор страницы (Блок 2)
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='general' pt='xs' className={styles.tabPanel}>
            {state.draftIds && (
              <UmkDetailGeneralInfo
                onSubmit={state.resetEditorModifiedState}
                draftIds={state.draftIds}
                id={state.id}
                isNewUmk={state.isNewUmk}
                setIsShouldBlock={state.setIsShouldBlock}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value='firstBlockEditor' pt='xs' className={styles.tabPanel}>
            <Box>
              {state.firstDraftId && state.activeTab === 'firstBlockEditor' && (
                <ContentEditorWrapper
                  editorName='firstBlockEditor'
                  draftId={state.firstDraftId}
                  updateEditorModified={state.updateEditorModified}
                />
              )}
            </Box>
          </Tabs.Panel>

          {!state.isDirections && (
            <Tabs.Panel value='secondBlockEditor' pt='xs' className={styles.tabPanel}>
              {state.secondDraftId && state.activeTab === 'secondBlockEditor' && (
                <ContentEditorWrapper
                  editorName='secondBlockEditor'
                  draftId={state.secondDraftId}
                  updateEditorModified={state.updateEditorModified}
                />
              )}
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
      {state.isDeleteModalOpen && (
        <ModalConfirm
          title='Удалить?'
          text='Дополнительная информация по УМК будет удалена'
          opened={state.isDeleteModalOpen}
          onClose={() => state.setDeleteModalOpen(false)}
          confirmHandler={state.handleDeleteUmk}
          subtitleProps={{ size: 'sm' }}
        />
      )}
      {state.isUnsavedChangesModalOpen && (
        <ModalConfirm
          title='Вы уверенны что хотите выйти?'
          text='У вас есть несохраненные изменения'
          opened={state.isUnsavedChangesModalOpen}
          onClose={state.onCancel}
          confirmHandler={state.onConfirmLeave}
          subtitleProps={{ size: 'sm' }}
        />
      )}
    </Box>
  );
};
