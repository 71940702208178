import React, { FC } from 'react';

import { ModalConfirm } from '@/ui/components/ModalConfirm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  disabled: boolean;
}

export const DeleteModal: FC<Props> = ({ isOpen, onClose, onSuccess, disabled }) => {
  return (
    <ModalConfirm
      title='Удалить вопрос?'
      text='Вопрос будет удален безвозвратно'
      opened={isOpen}
      onClose={onClose}
      disabled={disabled}
      confirmHandler={onSuccess}
      subtitleProps={{ size: 'sm' }}
    />
  );
};
