import React from 'react';

import { useToggleActiveMaterial } from '@/api/materials/hooks/useToggleActiveMaterial';

import { ModalConfirm } from '@/ui/components/ModalConfirm';
import {
  useGetMaterialsList,
  useMaterialsModalHelper,
} from '@/ui/containers/MaterialsContainer/Materials.hooks';
import { MaterialsModalsEnum } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

export const MaterialsActiveModal = () => {
  const { currentModal, onCloseModal, isOpen } = useMaterialsModalHelper(
    MaterialsModalsEnum.MATERIALS_ACTIVE_STATUS_MODAL,
  );

  const { toggleActive, isLoading } = useToggleActiveMaterial();
  const getMaterialsList = useGetMaterialsList();

  const updateActive = async () => {
    await toggleActive({ uuid: currentModal.currentId, isActive: !currentModal.isActive });
    onCloseModal();
    getMaterialsList();
  };

  const title = currentModal.isActive ? 'Убрать активность?' : 'Активировать материал?';
  const descriptionText = currentModal.isActive
    ? 'Материал перестанет отображаться на сайте. Его можно будет активировать в списке материалов или на детальной странице'
    : 'Материал станет отображаться на сайте. Его можно будет деактивировать в списке материалов или на детальной странице';

  return (
    <>
      <ModalConfirm
        title={title}
        text={descriptionText}
        opened={isOpen}
        onClose={onCloseModal}
        confirmHandler={updateActive}
        disabled={isLoading}
      />
    </>
  );
};
