import { useCallback } from 'react';

import { buildDataForUpdate } from '@/utils/getTransformMenuItems';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { MenuContextEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import {
  TEditMenuItem,
  TSettingsData,
  TTransformMenuItem,
} from '@/ui/containers/MenuContainer/MenuContainer.types';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

import {
  fetchUpdateMenuAction,
  setDeleteMenuItem,
  setDeleteMenuItemId,
  setEditMenuItem,
  setMenuItem,
  setParentId,
  setUpdatedMenuItem,
} from '@/store/slices/menu/menuItems';

interface UseMenuHandlersParams {
  dispatch: ReturnType<typeof useAppDispatch>;
  menuItems: TTransformMenuItem[] | null;
  currentTypeMenu: string | null;
  menuContext: MenuContextEnum;
  anotherContextItems: TSettingsData[] | null;
  deleteMenuItemId: string | null;
}

export const useMenuHandlers = ({
  dispatch,
  menuItems,
  currentTypeMenu,
  menuContext,
  anotherContextItems,
  deleteMenuItemId,
}: UseMenuHandlersParams) => {
  const onCreateNewMenuItem = useCallback(
    (values: Omit<TAddOrEditItem, 'id'>, id: string | null) => {
      dispatch(setMenuItem({ values, parentId: id }));
      dispatch(setParentId(null));
    },
    [dispatch],
  );

  const onEditMenuItemHandler = useCallback(
    (values: TEditMenuItem | Omit<TAddOrEditItem, 'id'>) => {
      dispatch(setUpdatedMenuItem({ updatedMenu: values, menuItems }));
      dispatch(setEditMenuItem({ item: null, parentId: null }));
    },
    [dispatch, menuItems],
  );

  const onDeleteHandler = useCallback(() => {
    if (deleteMenuItemId) {
      dispatch(setDeleteMenuItem(deleteMenuItemId));
      dispatch(setDeleteMenuItemId(null));
    }
  }, [dispatch, deleteMenuItemId]);

  const onSaveHandler = useCallback(() => {
    if (menuItems && currentTypeMenu) {
      dispatch(
        fetchUpdateMenuAction({
          data: buildDataForUpdate(menuItems, menuContext, anotherContextItems),
          menuType: currentTypeMenu,
        }),
      );
    }
  }, [dispatch, menuItems, currentTypeMenu, menuContext, anotherContextItems]);

  return { onCreateNewMenuItem, onEditMenuItemHandler, onDeleteHandler, onSaveHandler };
};
