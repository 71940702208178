import { FC, useCallback, useEffect } from 'react';
import { Box, Button, LoadingOverlay, ScrollArea, Tabs } from '@mantine/core';

import { getFlatDataStructure } from '@/utils/getTransformMenuItems';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { ModalConfirm } from '@/ui/components/ModalConfirm';
import { Page } from '@/ui/components/Page';
import PageBody from '@/ui/components/Page/components/PageBody/PageBody';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { AddOrEditItem } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem';
import { EAddNewItemConfirmationTexts } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem.constants';

import { MenuContextEnum, menuName } from './MenuContainer.constants';
import { useMenuHandlers } from './MenuContainer.handlers';
import { useMenuState } from './MenuContainer.state';
import { MenuTabSite } from './MenuTabSite';

import {
  fetchGetMenuItemsContextAction,
  setAddMenuItemsModalOpened,
  setDeleteMenuItemId,
  setMenuContext,
  setResetMenuItems,
} from '@/store/slices/menu/menuItems';
import { setTypeMenu } from '@/store/slices/menu/menuTypes';

export const MenuContainer: FC = () => {
  const dispatch = useAppDispatch();
  const {
    canUpdate,
    menuCode,
    menuAddItemsModal,
    deleteMenuItemId,
    anotherContextItems,
    menuItems,
    editMenuItem,
    menuContext,
    parentId,
    currentTypeMenu,
    isFetching,
    isUpdating,
    breadcrumbs,
    proceed,
    reset,
    state,
    currentParent,
  } = useMenuState();

  const { onCreateNewMenuItem, onEditMenuItemHandler, onDeleteHandler, onSaveHandler } =
    useMenuHandlers({
      dispatch,
      menuItems,
      currentTypeMenu,
      menuContext,
      anotherContextItems,
      deleteMenuItemId,
    });

  const onChangeTab = (context: MenuContextEnum) => dispatch(setMenuContext(context));
  const onConfirmLeave = useCallback(() => proceed && proceed(), [proceed]);
  const onCancel = useCallback(() => reset && reset(), [reset]);

  useEffect(() => {
    if (!currentTypeMenu) {
      // navigate('/menu');
    }
    return () => {
      dispatch(setResetMenuItems());
    };
  }, [currentTypeMenu, dispatch]);

  useEffect(() => {
    if (currentTypeMenu) {
      dispatch(fetchGetMenuItemsContextAction({ menuContext, menuType: currentTypeMenu }));
    } else {
      dispatch(setTypeMenu({ type: menuCode }));
    }
  }, [currentTypeMenu, dispatch, menuCode, menuContext]);

  return (
    <Page>
      <PageHeader
        title={`${currentTypeMenu ? menuName[currentTypeMenu] : 'Меню'}`}
        subTitle={`CODE: ${menuCode}`}
        backLink='/menu'
        breadcrumbs={breadcrumbs}
        rightButton={
          canUpdate && (
            <Button loading={isUpdating} onClick={onSaveHandler}>
              Сохранить
            </Button>
          )
        }
      />
      <PageBody>
        <Box>
          <ScrollArea.Autosize>
            <Tabs
              color='teal'
              defaultValue='first'
              keepMounted={false}
              style={{ height: '100%', position: 'relative' }}
            >
              <Tabs.List style={{ borderBottom: 'none' }}>
                <Tabs.Tab
                  value='first'
                  color='primary'
                  onClick={() => onChangeTab(MenuContextEnum.CORP)}
                >
                  Корпоративный сайт
                </Tabs.Tab>
                <Tabs.Tab
                  value='second'
                  color='primary'
                  onClick={() => onChangeTab(MenuContextEnum.USER)}
                >
                  Пользовательский сайт
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value='first' pt='xs' style={{ height: '90vh', overflow: 'hidden' }}>
                <MenuTabSite items={menuItems} />
              </Tabs.Panel>

              <Tabs.Panel
                value='second'
                pt='xs'
                style={{ padding: '24px 0 34px 0', height: '90vh', overflow: 'hidden' }}
              >
                <MenuTabSite items={menuItems} />
              </Tabs.Panel>
            </Tabs>
          </ScrollArea.Autosize>
          <LoadingOverlay visible={isFetching} style={{ position: 'absolute', top: '50%' }} />
        </Box>
      </PageBody>

      <AddOrEditItem
        parentId={parentId}
        visibilityParent={currentParent?.isVisible || true}
        context={menuContext}
        isOpened={menuAddItemsModal}
        onChange={onCreateNewMenuItem}
        onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
      />

      {editMenuItem && (
        <AddOrEditItem
          context={menuContext}
          visibilityParent={currentParent?.isVisible || true}
          editItem={getFlatDataStructure(editMenuItem)}
          isOpened={menuAddItemsModal}
          onChange={onEditMenuItemHandler}
          onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
        />
      )}
      {deleteMenuItemId && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.TITLE}
          text={EAddNewItemConfirmationTexts.REMOVE_FROM_ALL_SITES}
          confirmHandler={onDeleteHandler}
          opened={!!deleteMenuItemId}
          onClose={() => dispatch(setDeleteMenuItemId(null))}
        />
      )}
      {state === 'blocked' && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.OUT_TITLE}
          text={EAddNewItemConfirmationTexts.NOT_SAVE_MENU_SITE}
          confirmHandler={onConfirmLeave}
          opened={state === 'blocked'}
          onClose={onCancel}
        />
      )}
    </Page>
  );
};
