import { FC, PropsWithChildren } from 'react';
import { Box } from '@mantine/core';

export const Page: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      {children}
    </Box>
  );
};
